var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[(_vm.DISLoading)?_c('div',{staticClass:"loader"},[_c('img',{attrs:{"src":_vm.DISLogo,"alt":""}})]):_vm._e(),(_vm.stolenItem && _vm.isHZ)?_c('div',{staticClass:"stolen-message"},[_vm._v(" This item has been REPORTED stolen please remit to any Helzberg Store or Call "),_c('br'),_vm._v(" 1 (800) 435-9237 ")]):_vm._e(),(_vm.stolenItem && !_vm.isHZ)?_c('div',{staticClass:"stolen-message"},[_vm._v(" THIS ITEM HAS BEEN REPORTED STOLEN BELONGS TO TTC USA. PLEASE CONTACT OWNER TAL WHARSHAVSKY 213-400-6610 ")]):_vm._e(),(_vm.digitalCertificate)?_c('div',{staticClass:"container"},[(!_vm.showFullPageAd)?_c('div',{staticClass:"right"},[_c('video',{ref:"videoRef",staticClass:"dig-cert-vid",attrs:{"src":_vm.digitalCertificateVideoURL,"autoplay":"","muted":"","playsinline":""},domProps:{"muted":true},on:{"play":_vm.handleCertificateNumber,"timeupdate":_vm.handleTime,"ended":_vm.handleVideoPlayback}}),(_vm.showDISCert)?_c('div',{staticClass:"certificate-number-wrapper",class:{
          hz_settings: _vm.isHZ,
          isTemplate4: _vm.isTemplate4,
          has_footer_ad: _vm.has_footer_ad,
          has_imperfections: _vm.has_imperfections,
        }},[_c('strong',[_vm._v(" DIS CERTIFICATE#: "+_vm._s(_vm.digitalCertificate && _vm.digitalCertificate.ManufacturedAs === "Lab Grown" ? `LG${_vm.digitalCertificate.CertNum}` : _vm.$route.params.certificate_num))])]):_vm._e()]):_vm._e(),(
        _vm.$route.params.type.includes('diamond') &&
        _vm.imperfection.display_imperfection_icons
      )?_c('div',{staticClass:"imperfection_img"},[_c('img',{ref:"characteristics_url",staticClass:"img",attrs:{"src":_vm.imperfection.url,"alt":"Imperfection Template"}}),_c('div',{staticClass:"imperfections_key_holder"},[_c('p',{staticClass:"imperfection_chosen_text section_two_section_head"},[_vm._v(" Key to Symbols ")]),(
            _vm.imperfection.imperfectionAddedList &&
            _vm.imperfection.imperfectionAddedList.length
          )?_c('div',{staticClass:"key_info_holder"},[_vm._l((_vm.imperfection.imperfectionAddedList),function(imperfection,index){return _c('div',{key:index,staticClass:"imperfections_key"},[_c('img',{ref:"imperfection_url",refInFor:true,staticClass:"imperfection_img_icon",attrs:{"src":require('@/assets/img/imperfections/' + imperfection.image),"alt":"imperfection"}}),_c('p',{staticClass:"imperfections_key_text"},[_vm._v(_vm._s(imperfection.name))])])}),(!_vm.imperfection.display_imperfection_icons)?_c('div',{staticClass:"imperfections_key_noicon"},[_vm._m(0)]):_vm._e()],2):_vm._e()])]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.activateAds)?_c('ad-page',{attrs:{"currentCampaign":_vm.currentCampaign,"showFullPageAd":_vm.showFullPageAd,"showFooterAd":_vm.showFooterAd},on:{"handleAdToggle":_vm.handleAdToggle,"handle-analytics":function($event){return _vm.handleAnalytics('click', true)}}}):_vm._e()],1)],1):_vm._e(),(_vm.noCertificate && !_vm.DISLoading)?_c('div',{staticClass:"nocontent"},[_vm._v(" Virtual Certificate Video Does Not Exist or Hasn't been Uploaded Yet. "+_vm._s(`\n`)+" Redirecting you back to the home page in ..."+_vm._s(_vm.redirectTimer)+" ")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Red Symbols indicate internal characteristics. "),_c('br'),_vm._v(" Green symbols indicate external characteristics ")])
}]

export { render, staticRenderFns }